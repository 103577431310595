<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      title="Nova Exportação de BDGD"
      subtitle="Selecione o mês e entidades que serão exportadas"
      v-model="tab"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      class="mx-auto"
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
    >
      <exportar-bdgd-wizard-tabs
        ref="exportarBdgdParametros"
        :items="exportacoes"
        @step-observer="updateTabStatus($event)"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import ExportacoesBdgdsService from '@/services/ExportacoesBdgdsService';
import wizardMixins from '@/mixins/wizardMixins';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins, tourMixins],

  components: {
    ExportarBdgdWizardTabs: () =>
      import('@/components/geracao-bdgd/exportar-bdgd/ExportarBdgdWizardTabs'),
    MaterialWizard: () => import('@/components/base/MaterialWizard')
  },

  data: () => ({
    tabs: ['Mês dos Dados', 'Entidades', 'Confirmação'],
    loading: false,
    exportacoes: {},
    arrTourPrimeiraTab: [
      {
        element: '#first-step-exportacao-bdgd-table',
        intro:
          'Aqui você deverá selecionar o mês e versão da BDGD que será exportada.',
        scrollTo: 'tooltip',
        position: 'top'
      }
    ],
    arrTourSegundaTab: [
      {
        element: '#second-step-exportacao-bdgd-table',
        intro: 'Aqui você deverá selecionar as entidades que serão exportadas.',
        scrollTo: 'tooltip',
        position: 'top'
      },
      {
        element: '#second-step-search',
        intro: 'Neste campo você poderá pesquisar por entidades específicas.',
        scrollTo: 'tooltip',
        position: 'bottom'
      }
    ],
    arrTourTerceiraTab: [
      {
        element: '#third-step-exportacao-bdgd-confirm',
        intro:
          'Nessa seção será possivel visualizar o total de linhas a serem exportadas.',
        scrollTo: 'tooltip',
        position: 'top'
      }
    ]
  }),

  mounted() {
    this.iniciarTourPrimeiraTab();
  },

  methods: {
    save() {
      const data = this.$refs.exportarBdgdParametros.exportData();
      this.loading = true;

      ExportacoesBdgdsService.save(data)
        .then(() => {
          this.$toast.success('Exportação de BDGD salva com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['EXPORTAR_BDGD_HISTORICO']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar a Exportação de BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },

    iniciarTourPrimeiraTab() {
      this.doneLabel = 'Próximo';
      this.desativarTourAoSair = false;

      let onBeforeExitFunction = {
        func: () => {
          this.tab = 1;
          this.iniciarTourSegundaTab();
        }
      };

      this.iniciarTour(this.arrTourPrimeiraTab, [], [], [onBeforeExitFunction]);
    },

    iniciarTourSegundaTab() {
      let onBeforeExitFunction = {
        func: () => {
          this.tab = 2;
          this.iniciarTourTerceiraTab();
        }
      };

      this.iniciarTour(this.arrTourSegundaTab, [], [], [onBeforeExitFunction]);
    },

    iniciarTourTerceiraTab() {
      this.doneLabel = 'Finalizar';
      this.desativarTourAoSair = true;

      let onBeforeExitFunction = {
        func: () => (this.tab = 0)
      };

      this.iniciarTour(this.arrTourTerceiraTab, [], [], [onBeforeExitFunction]);
    }
  }
};
</script>
